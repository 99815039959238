import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import dataV from '@jiaminghi/data-view';
import axios from 'axios'

import highlightPlugin from '@highlightjs/vue-plugin'
import 'highlight.js/styles/default.css' // 引入内置样式

Vue.use(highlightPlugin)


Vue.prototype.axios = axios
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 自定义element 主题文件
import './assets/scss/element-variables.scss'
Vue.use(ElementUI)



// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import 'vue-awesome/icons/play.js';
import 'vue-awesome/icons/chevron-up.js';
import 'vue-awesome/icons/chevron-down.js';




// 引入字体库
import './assets/fonts/fonts.css'
import './assets/fonts/iconfont.css'



//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

// 请求间隔时间
Vue.prototype.$requestInterval = 60 * 1000;


// 定义一个全局mixin函数 
Vue.mixin({
  methods: {
    // 深克隆
    DeepClone(data) {
      if (typeof data !== 'object' || data === null) {
        return data
      }
      if (Array.isArray(data)) {
        return data.map(item => this.DeepClone(item))
      }
      const clone = {}
      for (const key in data) {
        clone[key] = this.DeepClone(data[key])
      }
      return clone
    }

  }
});

// 全局注册
Vue.component('icon', Icon);
// Vue.use(dataV);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
