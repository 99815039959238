<template>
  <div class="cmb-about">
    <div class="intro-model">
      <div class="title">中医综合评估基准 CTCMBenchmark</div>
      <div class="desc">
        CTCMB是一个全面评估模型在中医药领域表现的基准测试集。它包含中医知识问答、医学语言生成、中医推理、中医语言理解和中医诊断五个类别，涉及广泛的中医专业知识。该平台旨在全面评估模型对中医知识的掌握、理解和运用的能力。通过这一多层次、多维度的评估体系，CTCMB致力于推动中医药领域中人工智能技术的发展与进步。具体组成如下。
        你可在
        <a
          class="ext-link"
          target="_blank"
          href="https://github.com/Wayyuanyuan/CTCMB/blob/main/data"
          >GitHub</a
        >上查看和下载我们的数据集。
      </div>
      <div class="d-flex cmb-img-box">
        <img class="cmb-img" src="@/assets/images/cmb/CMB.png" alt="" />
        <!-- <PieChart></PieChart> -->
      </div>

      <div class="explain">
        <h3>数据集：5个类别,11个数据集</h3>
        <h3>5个类别（每个类别包含1-3个数据集）</h3>
        <div class="block-list">
          <li>中医知识问答：以选择、判断和问答形式考核中医基础。 </li>
          <li>医学语言生成：评估模型生成标准医学文本的能力。 </li>
          <li>中医推理：评估模型基于中医理论的推理能力。 </li>
          <li>中医语言理解：评估模型对中医专业语言的理解。 </li>
          <li>中医诊断：评估模型运用四诊合参的诊断能力。 </li>
        </div>

        <div>TCM-ED TCM-DS TCM-SRT 为客观题,其余为主观题，其中TCM-ED包含A型题（最佳选择题）、B型题（病历摘要型最佳选择题）、C型题（共用题干选择题）以及D型题（共用选项选择题）。</div>
      </div>
    </div>
    <div class="intro-model">
      <div class="title">数据</div>
      <div class="desc">

        我们的数据可以直接从
        <a
          class="ext-link"
          target="_blank"
          href="https://github.com/Wayyuanyuan/CTCMB/blob/main/data"
          >GitHub</a
        >下载。请参考我们的GitHub，了解如何访问和利用这些数据。
      </div>
    </div>
    <div class="intro-model">
      <div class="title">引用</div>
      <pre class="bibtax">
@article{
            title={},
            author={},
            journal={},
            year={}
}</pre
      >

      <pre class="bibtax">
@misc{cmedbenchmark,
            title={CTCMB:A comprehensive evaluation benchmark of Traditional Chinese Medicine},
            author={},
            note={},
            year = {2024},
            publisher = {GitHub},
            journal = {GitHub repository},
            howpublished = {\url{https://github.com/Wayyuanyuan/CTCMB/blob/main}},
}</pre
      >
    </div>
    <div class="intro-model">
      <div class="title">联系我们</div>
      <div class="desc">
        有关CTCMB的问题，请在
        <a
          class="ext-link"
          target="_blank"
          href="https://github.com/Wayyuanyuan/CTCMB/issues"
          >GitHub</a
        >
       上创建一个问题。如果你对潜在的合作感兴趣，请联系weiyy53@mail2.sysu.edu.cn。
      </div>
    </div>
  </div>
</template>
<script>
// import PieChart from "@/components/echarts/pieRound";
export default {
  // components: {
  //   PieChart
  // },
};
</script>

<style lang="scss" scoped>
.cmb-about {
  margin-top: 40px;
  .intro-model {
    text-align: left;
    margin-bottom: 60px;
    .title {
      height: 44px;
      font-weight: 500;
      font-size: 36px;
      color: #1d2129;
      line-height: 44px;
      margin-bottom: 24px;
    }
    .desc {
      font-weight: 400;
      font-size: 16px;
      color: #4e5969;
      line-height: 24px;

      .ext-link {
        color: #165dff;
        text-decoration-line: underline;
      }
    }

    .bibtax {
      padding: 16px;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      word-wrap: break-word;
      white-space: pre-wrap;
      margin-bottom: 24px;
    }
    .cmb-img-box {
      justify-content: center;
      .cmb-img {
        height: 723px;
      }
    }

    .explain {
      font-weight: 400;
      font-size: 16px;
      color: #4e5969;
      h3 {
        margin-bottom: 8px;
      }
      .block-list {
        margin-top: 12px;
        font-weight: 400;
        font-size: 16px;
        color: #4e5969;
        margin-bottom: 20px;
        li {
          line-height: 1.5;
        }
        .item {
          margin-left: 24px;
          list-style-type: circle;
        }
      }
    }
  }
}
</style>