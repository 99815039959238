<template>
  <div id="app">
    <NavMenu />
    <router-view />
    <!-- <ICP /> -->
    <!-- 回到顶部 -->
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import NavMenu from "@/components/common/NavMenu";
// import ICP from "@/components/common/ICP";

export default {
  name: "App",
  components: { NavMenu },
};
</script>

<style lang="scss">
// 自定义element 主题文件
#app {
  // font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-family: PingFang SC, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  height: 100vh;
  background-color: #fff;
}
@import './assets/scss/element-variables.scss'

</style>
