
    
<template>
  <div class="dataset-page">
    <!-- banner -->
    <div class="dataset-page_banner">
      <!-- title num -->
      <div class="data-list">
        <div class="data-item" v-for="(item, index) in titleList" :key="index">
          <div class="value">{{ item.value }}</div>
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="dataset-page_type container">
      <!-- 数据集类型板块 -->
      <div class="type_content">
        <el-tabs
          tab-position="left"
          class="left-tab"
          v-model="modelTabsActvieName"
          @tab-click="handleDimClick"
        >
          <el-tab-pane
            v-for="leftItem in originModelList"
            :key="leftItem.name"
            :label="leftItem.name"
            :name="leftItem.name"
          >
            <div class="content-box">
              <div class="header">
                <div class="title">{{ leftItem.name }}</div>
                <!-- <div class="desc"> -->
                  <!-- {{ leftItem.ChineseIntro }} -->
                  <!-- 暂无 -->
                <!-- </div> -->
              </div>

              <!-- 模型 -->
              <div v-if="datasetList.length">
                <div
                  class="item"
                  v-for="(datasetItem, j) in datasetList"
                  :key="j"
                  @click="jumpDetail(datasetItem)"
                >
                  <div class="item-title">{{ datasetItem.name }}</div>
                  <!-- <div class="item-label">
                  <el-tag type="success">开源</el-tag>
                  <el-tag type="info">暂无</el-tag>
                 
                </div> -->
                  <div class="item-desc">
                    {{ datasetItem.intro }}
                  </div>
                  <div class="item-lang">
                    是否开源:{{ datasetItem["open-source"] }}
                  </div>
                  <!-- <div class="item-source">
                    来源:{{ datasetItem.source }}暂无
                  </div> -->
                  <div class="item-bottom d-flex">
                    <div class="left">查看详情</div>
                    <div class="right d-flex">
                      <!-- <div class="d-flex view-w">
                      <i class="el-icon-view"></i>
                      <div class="value">2024 暂无</div>
                    </div> -->
                      <div class="d-flex view-w">
                        <i class="el-icon-time"></i>
                        <div class="value">{{ datasetItem.date }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="">持续建设中...</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
  
<script>
import originModelList from "@/assets/data/model/organization.json";
import modelList from "@/assets/data/model/model.json";

export default {
  data() {
    return {
      titleList: [
        { value: "20", name: "模型数量" },
        { value: "36", name: "模型版本数" },
        { value: "15", name: "国内模型数量" },
        { value: "4", name: "领域" },
      ],

      originModelList: originModelList.organizations, //维度列表
      datasetList: [], //对应维度的数据集
      modelList: modelList.models, //所有的数据集数据
      modelTabsActvieName: "Openai",
    };
  },
  mounted() {
    const name = this.originModelList[0].name;
    this.datasetList = this.modelList.filter(
      (item) => item.organization === name
    );
  },
  methods: {
    handleTypeClick(tab, event) {
      console.log(tab, event);
    },
    handleDimClick(tab) {
      const index = tab.index;
      const name = this.originModelList[index].name;
      // 匹配当前选择的维度中的EnglishName为数据集的维度dimension名称
      this.datasetList = this.modelList.filter(
        (item) => item.organization === name
      );
    },
    jumpDetail(item) {
      console.log("item.", item);
      this.$router.push({
        path: "/model-detail",
        query: {
          name: item.name,
        },
      });
    },
  },
};
</script>
  <style lang="scss" scoped >
::v-deep .el-tabs__item.is-disabled {
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
}

::v-deep .el-tabs--left .el-tabs__header.is-left {
  width: 160px;
}

.dataset-page {
  .dataset-page_banner {
    background: url("~@/assets/images/model/header_bg.png");
    height: 346px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;

    .data-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 70px;
    }

    .data-item {
      color: #1d2129;
      flex: 1 0;
      .value {
        font-family: Open Sans, Open Sans;
        font-weight: 800;
        font-size: 56px;
        height: 56px;
        line-height: 56px;
        margin-bottom: 20px;
      }
      .name {
        font-weight: bold;
        font-size: 16px;
        height: 28px;
        line-height: 28px;
        color: #4e5969;
      }
      .desc {
        font-weight: 400;
        font-size: 14px;
        color: #1d2129;
        height: 22px;
      }
    }
    .data-item:not(:last-child) {
      border-right: 1px solid #999;
    }
  }

  .dataset-page_type {
    width: 100%;
    margin-bottom: 32px;
    margin-top: 24px;

    .type_content {
      .content-box {
        .header {
          // height: 108px;
          background: #0e42d2;
          background: url("~@/assets/images/model/title_bg.png");
          background-size: cover;
          background-position: center;
          box-shadow: inset 0px -1px 0px 0px #e5e6e8;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #e5e6eb;
          text-align: left;
          padding: 16px 16px;
          margin-bottom: 16px;
          border-radius: 8px;

          .title {
            height: 28px;
            font-weight: 500;
            font-size: 20px;
            color: #1d2129;
            line-height: 28px;
            margin-bottom: 10px;
          }

          .desc {
            height: 22px;
            font-weight: 400;
            font-size: 14px;
            color: #1d2129;

            line-height: 22px;
          }
        }
        .item {
          padding: 16px;
          border-radius: 8px;
          border: 1px solid #e5e6eb;
          display: flex;
          flex-direction: column;
          align-items: start;
          width: 100%;
          margin-bottom: 16px;
          cursor: pointer;
          .item-title {
            height: 28px;
            font-weight: 500;
            font-size: 20px;
            color: #1d2129;
            line-height: 28px;
            margin-bottom: 8px;
          }
          .item-label {
            .el-tag {
              margin-right: 16px;
            }
          }
          .item-desc {
            font-weight: 400;
            font-size: 14px;
            color: #4e5969;
            text-align: left;
            line-height: 22px;
            margin-top: 16px;
            margin-bottom: 10px;
          }
          .item-lang,
          .item-source {
            height: 14px;
            font-weight: 400;
            font-size: 14px;
            color: #86909c;
            margin-bottom: 8px;
          }
          .item-bottom {
            width: 100%;
            margin-top: 16px;
            justify-content: space-between;
            font-size: 14px;
            color: #86909c;
            .left {
              height: 22px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #165dff;
              line-height: 22px;
              cursor: pointer;
            }
            .right {
              i {
                margin-right: 12px;
              }
              .view-w {
                margin-left: 36px;
              }
            }
          }
        }

        .item:hover {
          border: 1px solid #165dff;
        }
      }
    }
    .left-tab {
    }
  }
}
</style>
