<template>
  <div>
    <Echart :options="options" id="bar" height="380px" width="600px"></Echart>
  </div>
</template>
  
  <script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        const xAxisData = newData.data.map(item=>item.function)
        const yAxisData = newData.data.map(item=> Number(item.score).toFixed(2))

        this.options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "10%",
            bottom: "35%",
          },
          legend: {
            textStyle: {
              fontSize: 12,
              color: "#1D2129",
            },
          },

          xAxis: [
            {
              type: "category",
              data: xAxisData,
              axisLabel: {
                interval: 0, //   坐标轴刻度标签的显示间隔(在类目轴中有效哦)，默认会采用标签不重叠的方式显示标签（也就是默认会将部分文字显示不全）可以设置为0强制显示所有标签，如果设置为1，表示隔一个标签显示一个标签，如果为3，表示隔3个标签显示一个标签，以此类推
                rotate: 60, // 标签倾斜的角度，在类目轴的类目标签显示不全时可以通过旋转防止标签重叠（官方这样说的）旋转的角度是-90到90度
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              splitLine:{ //背景核度线
                show:true
              }
            },
          ],
          series: [
            {
              name: newData.name,
              type: "bar",
              barWidth: "60%",
              label: {
                show: true,
                formatter: `{c}`,
                position: "top",
                color: "rgba(29, 33, 41, 1)",
              },
              showBackground: true,
              backgroundStyle: {
                color: "rgba(22,93,255,0.08)",
              },
              itemStyle: {
                color: "rgba(190, 218, 255, 1)",
              },

              data:yAxisData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
  