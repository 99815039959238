<template>
    <div class="not-found">
      <div class="content">
        <h1 class="title">404</h1>
        <p class="subtitle">页面未找到</p>
        <p class="message">您访问的页面不存在</p>
        <router-link to="/" class="back-home">返回首页</router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  };
  </script>
  
  <style scoped>
  .not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    font-family: 'Arial', sans-serif;
    color: #333;
    text-align: center;
  }
  
  .content {
    max-width: 1200px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 100px;
    height: 100px;
    line-height: 100px;
    font-weight: bold;
    color: #165DFF;
    margin: 0;
    animation: fadeIn 1s ease-in-out;
  }
  
  .subtitle {
    font-size: 24px;
    margin: 10px 0;
    color: #555;
    animation: fadeIn 1.2s ease-in-out;
  }
  
  .message {
    font-size: 18px;
    margin: 10px 0;
    color: #777;
    animation: fadeIn 1.4s ease-in-out;
  }
  
  .back-home {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background: #165DFF;
    border-radius: 5px;
    text-decoration: none;
    transition: background 0.3s;
    animation: fadeIn 1.6s ease-in-out;
  }
  
  .back-home:hover {
    background: rgba(22, 93, 255, 0.8);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>